import "./App.css";
import Navbar from "./components/navbar/navbar";
import HeroSection from "./components/hero-section/HeroSection";
import OurMission from "./components/our-mission/OurMission";
import CallBack from "./components/callback/CallBack";
import Animations from "./components/Animations";
import ImageSequence from "./components/image-sequence/ImageSequence";
import SideBySideImageText from "./components/side-image-widget/SideBySideImageText";
import PDFDownloadSection from "./components/pdf-download/PDFDownloadSection";
import FamilyOfBrands from "./components/brands/Brands";
import BadgeBanner from "./components/badge-banner/BadgeBanner";
import VideoSection from "./components/video-section/VideoSection";
import SEOHelper from "./components/SEO/SEOHelper";
import { videoData, unitwistImages, clickcloseImages } from "./staticData";

// Helper component to group related content (e.g., VideoSection with additional components)
const VideoSectionGroup = ({ video, extraComponents, id }) => (
  <section id={id} className="video-section-group">
    <VideoSection
      videoUrl={video.videoUrl}
      videoUrlSub={video.videoUrlSub}
      posterUrl={video.posterUrl}
      heading={video.heading}
      descText={video.descText}
      logoUrl={video.logoUrl}
    />
    {extraComponents}
  </section>
);

function App() {
  // Schema for JSON-LD structured data
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Unitwist",
    "url": "https://unitwist.co.za",
    "logo": "https://unitwist.co.za/unitwistlogo.png",
    "description": "Unitwist offers innovative plumbing solutions including high-pressure fittings, Click Close taps, EasyBat pipe clips, and EasyTail water meter fittings for both professional plumbers and DIY enthusiasts.",
    "sameAs": [
      "https://www.facebook.com/unitwist",
      "https://www.instagram.com/unitwist",
      "https://www.linkedin.com/company/unitwist"
    ]
  };

  return (
    <>
      <SEOHelper 
        schema={schema}
      />
      <header>
        <Navbar />
        <HeroSection
          videoUrl={videoData.hero.videoUrl}
          posterUrl={videoData.hero.posterUrl}
          videoUrlSub={videoData.hero.videoUrlSub}
        />
      </header>

      <main>
        <div style={{ paddingTop: "30px" }}>
          <Animations animationPath={videoData.animations.path} />
        </div>

        <BadgeBanner />
        
        <section id="unitwist-images" aria-label="Unitwist Product Images">
          <ImageSequence images={unitwistImages} />
        </section>
        
        <section id="our-mission">
          <OurMission />
        </section>

        {/* High pressure section */}
        <VideoSectionGroup
          id="high-pressure"
          video={videoData.highPressure}
        />

        {/* Working with the system section */}
        <VideoSectionGroup
          id="working-with-system"
          video={videoData.workingWithSystem}
        />

        {/* Click close section */}
        <VideoSectionGroup
          id="click-close"
          video={videoData.clickClose}
          extraComponents={
            <>
              <SideBySideImageText
                imageUrl={videoData.sideBySideClickClose.imageUrl}
                heading={videoData.sideBySideClickClose.heading}
                body={videoData.sideBySideClickClose.body}
              />
              <div aria-label="Click Close Product Images">
                <ImageSequence images={clickcloseImages} />
              </div>
            </>
          }
        />

        {/* Easy Bat section */}
        <VideoSectionGroup
          id="easy-bat"
          video={videoData.easyBat}
          extraComponents={
            <SideBySideImageText
              percentageWidth={true}
              imageUrl={videoData.easyBatSideBySide.imageUrl}
              heading={videoData.easyBatSideBySide.heading}
              body={videoData.easyBatSideBySide.body}
            />
          }
        />

        {/* Easy Tail section */}
        <VideoSectionGroup
          id="easy-tail"
          video={videoData.easyTail}
        />

        <section id="product-catalog">
          <PDFDownloadSection
            pdfURL={videoData.pdfDownload.pdfURL}
            descText={videoData.pdfDownload.descText}
            heading={videoData.pdfDownload.heading}
            imageURL={videoData.pdfDownload.imageURL}
          />
        </section>
      </main>

      <footer>
        <CallBack />
        <FamilyOfBrands />
      </footer>
    </>
  );
}

export default App;
