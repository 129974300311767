import React from "react";
import styles from "./badge-banner.module.css";

export default function BadgeBanner() {

  return (
    <>
      <div className={styles.middleText}>
        Unitwist offers a 25-year guarantee on all pipes and fittings, ensuring
        quality and reliability. Produced in Kwazulu Natal, South Africa, with
        advanced manufacturing technology, our products meet the highest
        standards, thanks to thorough quality checks by our dedicated QC team.
      </div>

      <div className={styles.banner}>
        <div className={styles.badgeItem}>
          <img
            loading="lazy"
            className={styles.styledBannerImage}
            src={`https://i.imgur.com/LIYqD3w.png`}
            alt="Quality Guarantee"
          />
          <p className={styles.badgeText}>
            All our fittings come with a 25-year guarantee against manufacturing
            defects.
          </p>
        </div>
        <div className={styles.badgeItem}>
          <img
            loading="lazy"
            className={`${styles.styledBannerImage} ${styles.styledBannerImageSecond}`}
            src="https://i.imgur.com/4Wrvnee.png"
            alt="Local Manufacture"
          />
          <p className={styles.badgeText}>
            All fittings are proudly manufactured in South Africa, in KZN.
          </p>
        </div>
        <div className={styles.badgeItem}>
          <img
            loading="lazy"
            className={styles.styledBannerImage}
            src="https://i.imgur.com/qN1xQJN.png"
            alt="Versatility"
          />
          <p className={styles.badgeText}>
            Our products are designed to be both affordable and extremely
            versatile.
          </p>
        </div>
      </div>
    </>
  );
}
