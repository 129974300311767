import React from 'react';
import { Helmet } from 'react-helmet';

/**
 * SEOHelper component for managing page-specific SEO elements
 * @param {Object} props - Component props
 * @param {string} props.title - Page title
 * @param {string} props.description - Page description
 * @param {string} props.canonicalUrl - Canonical URL for the page
 * @param {string} props.imageUrl - Image URL for social sharing
 * @param {Object} props.schema - JSON-LD schema object
 */
const SEOHelper = ({ 
  title = "Unitwist - The Future of Plumbing | High-Pressure Fittings, Click Close Taps & More",
  description = "Unitwist - The Future of Plumbing. Fast, easy & versatile plumbing solutions with high-pressure fittings, Click Close taps, EasyBat pipe clips, and EasyTail water meter fittings.",
  canonicalUrl = "https://unitwist.co.za",
  imageUrl = "/unitwistlogo.png",
  schema = null
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      
      {/* JSON-LD Schema */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEOHelper;
