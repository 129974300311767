import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import styles from './callback.module.css';

export default function CallBack() {
  const [state, handleSubmit] = useForm("mdoqrzqb");

  if (state.succeeded) {
    return (
      <div id="callBackSection" className={styles.container}>
        <p className={styles.confirmationMessage}>Thanks for your submission!</p>
      </div>
    );
  }

  return (
    <div id="callBackSection">
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2 className={styles.title}>Request a Call Back</h2>
          <p className={styles.subtitle}>
            Let us know what you're looking for in our product.
            We'll take a look and see if this could be the start of something beautiful.
            Please get in touch and let's schedule time to talk!
          </p>
        </div>

        <form className={styles.form} onSubmit={handleSubmit}>
          <input 
            className={styles.input} 
            type="text" 
            name="message" 
            placeholder="Please type your message here" 
            required 
          />
          <input 
            className={styles.input} 
            type="email" 
            name="email" 
            placeholder="Your Email" 
            required 
          />
          <input 
            className={styles.input} 
            type="text" 
            name="name" 
            placeholder="Your Name" 
            required 
          />
          <select 
            className={styles.input} 
            name="province" 
            required 
          >
            <option value="">Select Your Province</option>
            <option value="Eastern Cape">Eastern Cape</option>
            <option value="Free State">Free State</option>
            <option value="Gauteng">Gauteng</option>
            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
            <option value="Limpopo">Limpopo</option>
            <option value="Mpumalanga">Mpumalanga</option>
            <option value="Northern Cape">Northern Cape</option>
            <option value="North West">North West</option>
            <option value="Western Cape">Western Cape</option>
          </select>
          <input 
            className={styles.input} 
            type="tel" 
            name="phone" 
            placeholder="Your Cell Number" 
            required 
          />
          <button className={styles.button} type="submit">
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
}
